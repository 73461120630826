const contractNames = {
  mainnet: {
    ActivePool: '0x6dBDB6D420c110290431E863A1A978AE53F69ebC',
    BorrowerOperations: '0xd366e016Ae0677CdCE93472e603b75051E022AD0',
    CdpManager: '0xc4cbaE499bb4Ca41E78f52F07f5d98c375711774',
    CollSurplusPool: '0x335982DaE827049d35f09D5ec927De2bc38df3De',
    EbtcFeed: '0xa9a65B1B1dDa8376527E89985b221B6bfCA1Dc9a',
    EBTCToken: '0x661c70333AA1850CcDBAe82776Bb436A0fCfeEfB',
    Governor: '0x2A095d44831C26cFB6aCb806A6531AE3CA32DBc1',
    HighSecTimelock: '0xaDDeE229Bd103bb5B10C3CdB595A01c425dd3264',
    LowSecTimelock: '0xE2F2D9e226e5236BeC4531FcBf1A22A7a2bD0602',
    PriceFeed: '0x6a24ECc18224857BD73A7aa53c2a4Eb43c17D5A8',
    SortedCdps: '0x591AcB5AE192c147948c12651a0a5f24f0529BE3',
  },
  sepolia: {
    ActivePool: '0x7e0A97660B477a79C2e9f472bbFCFd8A715D9E6f',
    BorrowerOperations: '0x58aa3dE50CfeF7450657C52766dD43da8747285e',
    CdpManager: '0xe89A0378237c21B94126f77eD5Fa0FDd2eDE2b36',
    CollSurplusPool: '0xd5E6e5c5F46fa1A038b62B0625e21B97a4897F8F',
    EbtcFeed: '0xF08F9Ae30e19f2CA9b0876a7Dc57E6695010dE40',
    EBTCToken: '0xa78F78Aba222d20cE82eA4868a9E00c954e469c0',
    Governor: '0x68Aa57A7b02c02f53A99e8Fd451CaE761210C5d2',
    HighSecTimelock: '0x0393846e97ab5Ec1DC8CB7A59Ee8505F72A6aEEb',
    LowSecTimelock: '0xaADf07C98E2420E4d995Ba41Db53399648f50076',
    PriceFeed: '0x240E4141E13c6613bBBC53049dFEc0dAC70D692e',
    SortedCdps: '0xbc79539670992A4E1948dAdB737472a2FaD7CEcf',
    ActivePool_old: '0x8EbA6a17Fcd480A96a87308C560170206186D3EA',
    BorrowerOperations_old: '0x3D6697c8Cfcc36b68a3077d0a94385B8904B1299',
    CdpManager_old: '0x39D6613ABEDd78a0b8edEEfE62cDEc85FCbe08c0',
    CollSurplusPool_old: '0x6a6Ee2146f851fA745016B9e5592379f775b4ff3',
    EbtcFeed_old: '0x14BBa0A866f49D1ef33096557e661bE20BeBADA0',
    EBTCToken_old: '0xeA2D83AA5e7da0668AdbddADAAc28e51318DDd86',
    Governor_old: '0x85A074FF5adDf47fD66c94C780E7596922B3d1D9',
    HighSecTimelock_old: '0x0393846e97ab5Ec1DC8CB7A59Ee8505F72A6aEEb',
    LowSecTimelock_old: '0xaADf07C98E2420E4d995Ba41Db53399648f50076',
    PriceFeed_old: '0xd2Bdc5Acb219ECeEE3578DA86774BBE4dd85665E',
    SortedCdps_old: '0x0ADF592E25ba775b89cA37e0Fc01420CA96563C8',
  },
};

export default contractNames;
